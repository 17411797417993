export default (index, key, newVal = null, clear = false) => {
  if (localStorage) {
    let localObj = {};
    if (Object.prototype.hasOwnProperty.call(localStorage, index)) {
      localObj = JSON.parse(localStorage[index]);
    }
    if (typeof localObj === "object" && "hasOwnProperty" in localObj) {
      if (newVal) {
        localObj[key] = newVal;
        localStorage[index] = JSON.stringify(localObj);
      } else if (
        Object.prototype.hasOwnProperty.call(localObj, key) ||
        key === undefined
      ) {
        if (clear) {
          if (key === undefined) {
            localStorage.removeItem(index);
          } else {
            delete localObj[key];
            localStorage[index] = JSON.stringify(localObj);
          }
        } else {
          if (key === undefined) {
            return localObj;
          } else {
            return localObj[key];
          }
        }
      }
    }
  }
};
