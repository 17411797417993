// eslint-disable-next-line no-unused-vars
const moduleName = "webSocket";

function initialState() {
  return {
    webSocketConnection: null,
    webSocketId: null,
    webSocketQueue: {},
    webSocketURL: {
      production:
        "wss://lxr7pw0p36.execute-api.us-east-2.amazonaws.com/production",
      test: "wss://7scz7pj6bl.execute-api.us-east-2.amazonaws.com/test",
    },
    joinSocketPromise: null,
    keepAlive: 1000 * 60 * 5, // every 5 minutes
    keepAliveTimeout: null,
    keepAliveCounter: 0,
    maxKeepAliveCount: 12, // Socket can remain open a max of 2 hours.  This limits it to one.
  };
}

export default {
  namespaced: true,
  state: initialState(),
  //   modules: {},
  mutations: {
    addMessageToQueue(state, payload) {
      let context = payload.context;
      delete payload.context;
      state.webSocketQueue[payload.message.messageId] = payload;
      context.dispatch("handleQueue");
    },
    saveWebSocket(state, payload) {
      state.webSocketConnection = payload;
    },
    receiveMessage(state, payload) {
      let messageId = payload.messageId;
      if (
        Object.prototype.hasOwnProperty.call(state.webSocketQueue, messageId)
      ) {
        if (payload.action == "acknowledge") {
          if (state.webSocketQueue[messageId].status == "sent") {
            state.webSocketQueue[messageId].status = "received";
          }
        } else if (payload.action == "respond") {
          state.webSocketQueue[messageId].status = "complete";
          state.webSocketQueue[messageId].promise.resolve(payload.data);
        } else if (payload.action == "error") {
          state.webSocketQueue[messageId].status = "error";
          state.webSocketQueue[messageId].promise.reject(payload.data);
        } else {
          console.log(
            "received unknown action for message " + messageId + ": ",
            payload
          );
        }
      } else {
        console.log("received unknown message: ", payload);
      }
    },
    saveWebSocketId(state, payload) {
      state.webSocketId = payload;
      state.joinSocketPromise.isResolved = true;
      state.joinSocketPromise.resolve(payload);
    },
    sendMessageId(state, messageId) {
      if (
        Object.prototype.hasOwnProperty.call(state.webSocketQueue, messageId) &&
        state.webSocketQueue[messageId].status == "unsent" &&
        state.webSocketConnection != null
      ) {
        state.webSocketConnection.send(
          JSON.stringify(state.webSocketQueue[messageId].message)
        );
        state.webSocketQueue[messageId].status = "sent";
      }
    },
    saveJoinSocketPromise(state, payload) {
      state.joinSocketPromise = {
        promise: payload.returnPromise,
        resolve: payload.resolve,
        reject: payload.reject,
        isResolved: false,
      };
    },
    clearSocket(state) {
      let rejectStatuses = ["unsent", "send", "received"];
      for (let messageId in state.webSocketQueue) {
        if (rejectStatuses.includes(state.webSocketQueue[messageId].status)) {
          state.webSocketQueue[messageId].promise.reject("Socket timed out");
        }
      }
      if (
        state.joinSocketPromise != null &&
        !state.joinSocketPromise.isResolved
      ) {
        state.joinSocketPromise.reject("Socket timed out");
      }
      if (state.keepAliveTimeout != null) {
        clearTimeout(state.keepAliveTimeout);
      }
      if (
        state.webSocketConnection != null &&
        state.webSocketConnection.readyState < 2
      ) {
        state.webSocketConnection.close();
      }
      let newState = initialState();
      for (let key in newState) {
        state[key] = newState[key];
      }
    },
    saveKeepAlive(state, payload) {
      state.keepAliveTimeout = payload;
    },
    incrementKeepAliveCounter(state) {
      state.keepAliveCounter++;
    },
  },
  actions: {
    resetStore(context) {
      context.commit("clearSocket");
    },
    joinSocket(context) {
      //   return context;
      if (context.state.joinSocketPromise == null) {
        let resolve, reject;
        let returnPromise = new Promise((res, rej) => {
          resolve = res;
          reject = rej;
        });
        context.commit("saveJoinSocketPromise", {
          returnPromise,
          resolve,
          reject,
        });
        let tempSocket = new WebSocket(context.getters["webSocketURL"]);
        context.commit("saveWebSocket", tempSocket);
        tempSocket.addEventListener("open", async () => {
          let connectionId = await context.dispatch("sendSocketMessage", {
            action: "identify",
          });
          context.commit("saveWebSocketId", connectionId);
          context.dispatch("keepAlive");
        });
        tempSocket.addEventListener("close", () => {
          context.commit("clearSocket");
        });
        tempSocket.addEventListener("message", (event) => {
          let data = JSON.parse(event.data);
          context.commit("receiveMessage", data);
        });
        return returnPromise;
      } else if (!context.state.joinSocketPromise.isResolved) {
        // the promise has been returned but not yet resolved
        return context.state.joinSocketPromise.promise;
      } else {
        // the promise has been returned and is already resolved
        return new Promise((resolve) => {
          resolve(context.state.webSocketId);
        });
      }
    },
    sendSocketMessage(context, data) {
      let resolve, reject;
      let returnPromise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
      let payload = {
        message: {
          messageId: context.getters["uniqueMessageId"](),
          data: data,
        },
        status: "unsent",
        promise: { resolve, reject },
        context,
      };
      context.commit("addMessageToQueue", payload);
      return returnPromise;
    },
    handleQueue: async (context) => {
      if (context.state.webSocketConnection == null) {
        // In this case, the socket connection isn't established and we need to do so.
        await context.dispatch("joinSocket");
      }
      for (let messageKey in context.state.webSocketQueue) {
        if (context.state.webSocketQueue[messageKey].status == "unsent") {
          context.commit("sendMessageId", messageKey);
        }
      }
    },
    keepAlive: async (context) => {
      if (context.state.keepAliveTimeout != null) {
        await context.dispatch("sendSocketMessage", {
          action: "keep-alive",
        });
        context.commit("incrementKeepAliveCounter");
        if (context.state.keepAliveCounter >= context.state.maxKeepAliveCount) {
          //... I guess we're done.  Don't try to keep alive any more.
          // In fact, maybe reset?
          return;
        }
      }
      let madeTimeout = setTimeout(
        context.dispatch.bind(context, "keepAlive"),
        context.state.keepAlive
      );
      context.commit("saveKeepAlive", madeTimeout);
    },
  },
  getters: {
    webSocketURL(state, getters, rootState, rootGetters) {
      return state.webSocketURL[rootGetters["environment"]];
    },
    uniqueMessageId: (state) => () => {
      // function execution is to avoid caching
      let key = "";
      while (
        key == "" ||
        Object.prototype.hasOwnProperty.call(state.webSocketQueue, key)
      ) {
        key = ("" + performance.now()).replace(/\D/gi, "");
      }
      return key;
    },
  },
};
