import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 580,
      sm: 760,
      md: 960,
      lg: 1200,
    },
    scrollBarWidth: 16,
  },
});
