// eslint-disable-next-line no-unused-vars
const moduleName = "util";

function initialState() {
  return {
    goodChars:
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!@#$%^&*()-_=+/?.>,<;:'[]{}",
    scrambleChars:
      "5<idQIP0b#A_V$XNE):6KjWtLwv&4^k-+xo}G8ur%aO=/{M2p;J*Zl9q,e7'Rz]FB.[y(UH?CSgcsn@!f3DY>T1hm",
  };
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {},
  actions: {
    resetStore() {
      // doesn't need to do anything, stub for compatibility
    },
  },
  getters: {
    unscramble: () => (message, count) => {
      // message = decodeURIComponent(message);
      count = count ? count : 32;
      let tick = true;
      let out1 = "";
      let out2 = "";
      let len = message.length;
      for (let i = 0; i < len; i++) {
        if (tick && count) {
          out1 += message[i];
          count--;
        } else {
          out2 += message[i];
        }
        tick = !tick;
      }
      return [out1, out2];
    },
    replace: (state) => (message) => {
      let out = "";
      for (let i = 0; i < message.length; i++) {
        out += state.scrambleChars[state.goodChars.indexOf(message[i])];
      }
      return out;
    },
    unreplace: (state) => (message) => {
      let out = "";
      for (let i = 0; i < message.length; i++) {
        out += state.goodChars[state.scrambleChars.indexOf(message[i])];
      }
      return out;
    },
  },
};
