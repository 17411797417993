import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "PFYT - Home",
    },
    component: Home,
  },
  {
    path: "/getStarted",
    name: "Get Started",
    meta: {
      title: "PFYT - Write Message",
    },
    component: () =>
      import(/* webpackChunkName: "GetStarted" */ "../views/getStarted.vue"),
  },
  {
    path: "/faq",
    name: "FAQ",
    meta: {
      title: "PFYT - FAQ",
    },
    component: () =>
      import(/* webpackChunkName: "FAQ" */ "../views/HomeFAQ.vue"),
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: "PFYT - About",
    },
    component: () =>
      import(/* webpackChunkName: "About" */ "../views/About.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "PFYT - Login",
    },
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/login.vue"),
  },
  {
    path: "/u/:login",
    name: "Scan Code",
    meta: {
      title: "PFYT - User Home",
    },
    component: () =>
      import(/* webpackChunkName: "ScanCode" */ "../views/userHome.vue"),
  },
  {
    path: "/admin/:login",
    name: "Control Panel",
    meta: {
      title: "PFYT - Admin Home",
    },
    component: () =>
      import(/* webpackChunkName: "ControlPanel" */ "../views/admin.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    meta: { title: "Oops!" },
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
