<template>
  <v-card
    color="white"
    :elevation="isDisabled ? 0 : 5"
    rounded="lg"
    :disabled="isDisabled"
    @click.native="$emit('click', $event)"
    :style="`
      ${noClick ? '' : 'cursor: pointer;'}
      border-width: 0.3vmax;
      border-style: solid;
      border-color: #aaa !important;`"
    ref="rootCard"
    v-resize="handleResize"
  >
    <v-responsive :aspect-ratio="aspectRatio" class="pa-0 ma-0">
      <center class="d-flex flex-column" style="min-height: 100%">
        <v-spacer /><v-spacer />
        <slot name="content">
          <div v-if="showProgress">
            <v-progress-circular
              :size="iconSize"
              :color="color"
              :value="progress"
              rotate="-90"
              :width="Math.ceil(iconSize / 8)"
              class="no-transition"
              ><small v-if="iconSize <= 45">{{ progressText }}</small
              ><span v-else>{{ progressText }}</span></v-progress-circular
            >
          </div>
          <v-icon :size="iconSize" :color="color" v-if="showIcon">{{
            icon
          }}</v-icon>
          <div v-if="showContent" class="d-flex flex-column justify-center">
            <span
              v-for="(item, index) in contentParts"
              :key="index"
              :style="contentStyle(item)"
              :class="contentTextClass"
              v-html="item.content"
            />
          </div>
        </slot>
        <v-spacer
          v-if="
            (showIcon || showProgress || showContent || hasContent) && hasHeader
          "
        />
        <h2 style="opacity: 80%" class="mx-1" v-if="!small">
          <span ref="header"><slot /></span>
        </h2>
        <h3 style="opacity: 80%" class="mx-1" v-if="small">
          <span ref="header"><slot /></span>
        </h3>
        <v-spacer /><v-spacer />
      </center>
    </v-responsive>
  </v-card>
</template>

<style>
.no-transition * {
  transition: none !important;
}
</style>

<script>
export default {
  name: "TileCard",
  props: ["icon", "color", "size", "disabled", "progress", "content"],
  data() {
    return {
      componentWidth: 0,
      stableCount: 0,
      hasHeader: false,
      hasContent: false,
      isMounted: false,
    };
  },
  computed: {
    noClick() {
      return (
        this.$attrs["no-click"] ||
        this.$attrs["no-click"] === "" ||
        this.$attrs.noClick ||
        this.$attrs.noClick === ""
      );
    },
    aspectRatio() {
      let passedValue = Number(this.$attrs["aspect-ratio"]);
      if (!Number.isNaN(passedValue)) {
        return passedValue;
      } else {
        return 1;
      }
    },
    override() {
      return this.$attrs.override || this.$attrs.override === "";
    },
    small() {
      return this.$attrs.small || this.$attrs.small === "";
    },
    showIcon() {
      return this.icon && !this.hasContent;
    },
    showContent() {
      return this.content !== undefined && !this.hasContent;
    },
    useColor() {
      return this.color && this.color.length && this.color[0] == "#";
    },
    contentTextClass() {
      if (this.color && !this.useColor) {
        let modifier;
        let amount;
        let base;
        if (this.color.includes("lighten")) {
          modifier = "lighten";
        } else if (this.color.includes("darken")) {
          modifier = "darken";
        }
        if (modifier) {
          amount = Number(this.color.split(`${modifier}-`)[1]);
        }
        base = this.color.split(" ")[0];
        return `${base}--text${modifier ? ` text--${modifier}-${amount}` : ""}`;
      } else {
        return "";
      }
    },
    contentParts() {
      if (this.showContent) {
        if (Array.isArray(this.content)) {
          let out = [];
          let totalSize = 0;
          let needsMult = 0;
          for (let item of this.content) {
            if (
              typeof item === "string" ||
              item instanceof String ||
              typeof item === "number" ||
              item instanceof Number
            ) {
              // no total size, don't worry about
              out.push({
                content: item,
                mult: 0,
              });
              needsMult++;
            } else if (typeof item === "object" || item instanceof Object) {
              // MIGHT have a size
              let outPush = {};
              if (Object.prototype.hasOwnProperty.call(item, "mult")) {
                totalSize += item.mult;
                outPush.mult = item.mult;
              } else {
                outPush.mult = 0;
                needsMult++;
              }
              if (Object.prototype.hasOwnProperty.call(item, "content")) {
                outPush.content = item.content;
              } else {
                outPush.content = item;
              }
              out.push(outPush);
            } else {
              console.warn("Received unknown item type: ", item);
            }
          }
          let equalSize = 0;
          if (needsMult) {
            equalSize = (1 - totalSize) / needsMult;
          }
          for (let i = 0; i < out.length; i++) {
            if (out[i].mult == 0) {
              out[i].mult = equalSize;
            }
          }
          return out;
        } else {
          let isNum = `${Number(this.content)}` == this.content;
          let date = new Date(this.content);
          if (date.toString() != "Invalid Date" && !isNum) {
            let months = [
              "JAN",
              "FEB",
              "MAR",
              "APR",
              "MAY",
              "JUN",
              "JUL",
              "AUG",
              "SEP",
              "OCT",
              "NOV",
              "DEC",
            ];
            return [
              {
                content: months[date.getMonth()],
                mult: 0.2,
              },
              {
                content: date.getDate(),
                mult: 0.6,
              },
              {
                content: date.getFullYear(),
                mult: 0.2,
              },
            ];
          } else {
            return [
              {
                content: this.content,
                mult: 0.8,
              },
            ];
          }
        }
      } else {
        return [];
      }
    },
    progressText() {
      return this.$attrs.progressText || "";
    },
    showProgress() {
      return (
        this.progress !== undefined &&
        this.progress !== null &&
        !this.showContent &&
        !this.showIcon &&
        !this.hasContent
      );
    },
    circleWidth() {
      if (this.showProgress) {
        return Math.max(Math.ceil(this.iconSize / 8), 4);
      } else {
        return 4;
      }
    },
    isDisabled() {
      return this.disabled === "" || this.disabled;
    },
    iconSize() {
      let def = 0.5;
      if (this.size) {
        try {
          let temp = Number(this.size);
          if (temp) {
            def = temp;
          }
        } catch (err) {
          // don't care
        }
      }
      return Math.max(def * this.componentWidth, this.showProgress ? 2 : 0);
    },
    topPad() {
      let def = 0.25;
      if (this.size) {
        try {
          def = Number(this.size) / 2;
        } catch (err) {
          // don't care
        }
      }
      return def * this.componentWidth;
    },
  },
  methods: {
    contentStyle(item) {
      let rounded = Math.round(this.iconSize * item.mult);
      let out = `font-size: ${rounded}px; line-height: ${rounded}px;`;
      if (this.useColor) {
        out += ` color: ${this.color};`;
      }
      return out;
    },
    handleResize(waitForStable = false) {
      if (!this.isMounted) {
        return;
      }
      let self = this;
      let timeout = false;
      let newCompWidth = this.componentWidth;
      try {
        newCompWidth = self.$refs.rootCard.$el.offsetWidth;
        if (waitForStable) {
          if (newCompWidth != self.componentWidth) {
            self.stableCount = 0;
          } else {
            self.stableCount++;
          }
          if (self.stableCount < 3) {
            timeout = true;
          }
        }
      } catch (err) {
        timeout = true;
      }
      if (timeout) {
        self.$nextTick(self.handleResize.bind(self, waitForStable));
      }
      this.componentWidth = newCompWidth;
    },
  },
  mounted() {
    this.isMounted = true;
    this.handleResize(true);
    if (this.$refs.header.textContent) {
      this.hasHeader = true;
    }
    if (this.$slots.content !== undefined) {
      this.hasContent = true;
    }
  },
};
</script>
