<template>
  <div>
    <div class="d-flex flex-column justify-space-between fill-height">
      <v-row class="flex-grow-0">
        <v-col cols="6" md="4" lg="3">
          <tileCard
            icon="mdi-qrcode-scan"
            color="green"
            @click="$router.push('login')"
            v-ripple
          >
            Scan a QR Code
          </tileCard>
        </v-col>
        <v-col cols="6" md="4" lg="3">
          <tileCard
            icon="mdi-message-plus-outline"
            color="info"
            @click="$router.push('getStarted')"
            v-ripple
          >
            Create Message
          </tileCard>
        </v-col>
        <v-col cols="6" md="4" lg="3">
          <tileCard
            icon="mdi-book-open-page-variant-outline"
            color="purple lighten-1"
            @click="$router.push('about')"
            v-ripple
          >
            About
          </tileCard>
        </v-col>
        <v-col cols="6" md="4" lg="3">
          <tileCard
            icon="mdi-frequently-asked-questions"
            color="red"
            @click="$router.push('faq')"
            v-ripple
          >
            Support
          </tileCard>
        </v-col>
      </v-row>
      <!--<h2>Welcome to Penny For Your Thoughts!</h2>
    <br />
    To scan a QR code, press the "scan" icon in the top-right corner of the
    navigation bar. If you have no idea why you would want to scan a QR code on
    this website, continue reading!
    <br /><br />
    <h3>What is "Penny For Your Thoughts?"</h3>
    <br />
    "Penny For Your Thoughts" is a service that allows you to write a message
    and give it to somebody - often a significant other - to uncover, one day at
    a time. The message or letter is separated into small pieces according to
    your choice - it could be every word, every 3 letters, every sentence, etc.
    For each piece of the message, this website will generate a QR code. When
    the QR code is scanned, it will reveal that portion of the message. When all
    the QR codes have been scanned, the full message or letter is visible. You
    can customize the process, including how many pieces to use, how many QR
    codes can be scanned each day, whether the message on each QR code is
    revealed at the time of scan or only when everything is uncovered, and
    whether to allow the recipient to see the currently-uncovered portions of
    the message at any point, or only when it's all completely uncovered. Each
    QR code is designed to be printed on a sticky circular label and attached to
    a penny, hence the name "Penny For Your Thoughts."
    <br /><br />
    <h3>How does it work?</h3>
    <br />
    You'll write a message or letter, and choose how to divide it up - this
    website will tell you how many pieces it's getting split into. Once you're
    happy, you can pay to have the labels printed and shipped to you, or you can
    print them yourself. Stick the labels on whatever, probably pennies (which
    is what it's designed for, anyway), and give them to your significant other.
    One more QR code will be generated, which will link the person to this
    website. Each day, they can scan the big QR code to get here, then scan a
    penny (or multiple, depending on the settings you choose) to reveal a piece
    of the message.
    <br /><br />
    As an example, I designed this originally to give my wife a unique gift. I
    wrote about 1500 words (about 2 pages single-spaced) in a letter, then
    generated all the QR codes, printed them, attached them to pennies, and
    filled up a pickle jar. Each day she was supposed to take one penny out,
    scan it, record the information, and put it in a "completed" jar. It would
    take 3-4 years, but then my whole letter would be uncovered and she could
    read it.
    <br /><br />
    In practice, my original implementation was a little... problematic. She had
    to track carefully what she uncovered and monitor things carefully with a
    spreadsheet to make sure she was on track, so it ended up being a chore. I
    put some more effort in and turned it into this website to do all the
    tracking automatically - then decided to take it a step further to make it
    accessible for anybody.
    <br /><br />
    If you're interested, go ahead and click on the link below to make your own!
    <br /><br />
    <v-btn to="/getStarted" color="info">Get Started!</v-btn>
    <br /><br /><br />-->
      <div class="flex-grow-0">
        QR Code is registered trademark of
        <a href="https://www.qrcode.com">DENSO WAVE INCORPORATED</a>
      </div>
    </div>
  </div>
</template>

<script>
import tileCard from "@/components/tileCard.vue";

export default {
  name: "Home",
  components: {
    tileCard,
  },
};
</script>
