import Vue from "vue";
import Vuex from "vuex";
import webSocket from "./webSocket.js";
import util from "./util.js";
import localStoreFunction from "./localStoreFunction.js";

Vue.use(Vuex);

let storeModule;

function initialState() {
  return {
    localEnvironment: "test",
    errors: [],
    loading: [],
    windowSize: {
      width: 9999,
      height: 9999,
    },
  };
}

storeModule = {
  state: initialState(),
  mutations: {
    clearErrors(state) {
      state.errors = [];
    },
    addError(state, error) {
      let errObj = {};
      if (error.constructor === String) {
        errObj.text = error;
        errObj.canClose = true;
      } else {
        errObj = error;
      }
      state.errors.push(errObj);
    },
    removeError(state, errText) {
      for (let i = state.errors.length - 1; i >= 0; i--) {
        if (state.errors[i].text == errText) {
          state.errors.splice(i, 1);
        }
      }
    },
    addLoading(state, loading) {
      let loadingObj = {};
      if (loading.constructor === String) {
        loadingObj.text = loading;
        loadingObj.shouldShow = true;
      } else {
        loadingObj = loading;
      }
      state.loading.push(loadingObj);
    },
    removeLoading(state, loading) {
      for (let i = state.loading.length - 1; i >= 0; i--) {
        if (state.loading[i].text == loading) {
          state.loading.splice(i, 1);
          break;
        }
      }
    },
    fullReset(state, toPreserve) {
      let newState = initialState();
      for (let key in newState) {
        if (!toPreserve.includes(key)) {
          state[key] = newState[key];
        }
      }
    },
  },
  actions: {
    async resetStore(context) {
      let promises = [];
      for (let key in storeModule.modules) {
        promises.push(context.dispatch(`${key}/resetStore`));
      }
      await Promise.all(promises);
      context.commit("fullReset", ["windowSize"]);
    },
    getTempID(context) {
      return context.dispatch("webSocket/joinSocket");
    },
    capturePayment(context, paymentId) {
      return context.dispatch("webSocket/sendSocketMessage", {
        action: "capture",
        orderID: paymentId,
      });
    },
    getTime(context) {
      return context.dispatch("webSocket/sendSocketMessage", {
        action: "getTime",
      });
    },
    clearLocalStorage() {
      if (localStorage) {
        let keys = Object.keys(localStorage);
        for (let key of keys) {
          if (key.substring(0, 11).toLowerCase() == "aws.cognito") {
            localStorage.removeItem(key);
          }
        }
      }
    },
  },
  modules: {
    webSocket,
    util,
  },
  getters: {
    environment(state) {
      if (window.location.href.includes(":80")) {
        return state.localEnvironment;
      } else if (window.location.href.split(".xyz")[0].includes("://pfyt")) {
        return "production";
      } else {
        return "test";
      }
    },
    baseUrl(state, getters) {
      if (getters.environment == "test") {
        return "https://test.pfyt.xyz";
      } else {
        return "https://pfyt.xyz";
      }
    },
    windowSize(state) {
      return state.windowSize;
    },
    errors(state) {
      let out = [];
      for (let errorObj of state.errors) {
        out.push(errorObj.text);
      }
      return out;
    },
    closeErrors(state) {
      let out = true;
      for (let errorObj of state.errors) {
        out = out && errorObj.canClose;
        if (!out) {
          break;
        }
      }
      return out;
    },
    topLoading(state) {
      if (state.loading.length) {
        return state.loading[0];
      } else {
        return null;
      }
    },
    dateString: () => (date) => {
      try {
        let d = new Date(date);
        let months = [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ];
        return `${("0" + d.getDate()).slice(-2)}-${
          months[d.getMonth()]
        }-${d.getFullYear()}`;
      } catch (e) {
        return "";
      }
    },
    formatUUID: () => (str) => {
      let len = [8, 4, 4, 4, 12];
      let out = "";
      let total = 0;
      for (let i = 0; i < len.length; i++) {
        let it = len[i];
        out += str.substring(total, it + total);
        if (i != len.length - 1) {
          out += "-";
          total += it;
        }
      }
      return out;
    },
    localStore: () => localStoreFunction,
    shuffleArray: () => (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },
    emailRegex() {
      return /[^@]*@[^.]*\..+/;
    },
    labelLink() {
      return "https://www.amazon.com/gp/product/B07G7HR6DV/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07G7HR6DV&linkCode=as2&tag=pfyt-20&linkId=1786df3813a77f3e7de99c699c34dd4f";
    },
  },
};

export default new Vuex.Store(storeModule);
